<template>
  <div class="slot_header">
    <div class="slot_header_header">
      <img v-if="showBackIcon" class="slot_header_header_back" src="@/assets/images/public/ic_comon_back.webp"
        @click="back" />
      <div class="slot_header_header_content">
        <slot></slot>
      </div>
      <img v-if="showIcon" class="slot_header_header_icon" :src="require('@/assets/' + imageName + '')"
        @click="handleIconClick" />
    </div>
    <div class="divider" />
  </div>
</template>

<script>
export default {
  name: "SwitchHeader",
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    showBackIcon: {
      type: Boolean,
      default: true,
    },
    imageName: {
      type: String,
      default: 'images/public/ic_my_money_course.webp',
    }
  },
  computed: {
    title() {
      return this.$route.meta.name;
    },
  },
  methods: {
    back() {
      this.$emit("onBack");
    },
    handleIconClick() {
      this.$emit("onIconClick");
    },
  },
  created() { },
};
</script>

<style lang="scss" scoped>
.slot_header {
  background: var(--white);

  &_header {
    padding: 1em;
    display: flex;
    align-items: center;

    &_back {
      width: 1.5em;
      height: auto;
    }

    &_content {
      flex: auto 1 0;
      font-size: 1.3rem;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: baseline;
    }

    &_icon {
      width: 2em;
      height: auto;
    }
  }
}
</style>
